































import Vue from "vue";
import Component from "vue-class-component";
import { Cell, CellGroup, Button, PullRefresh, List, Dialog } from "vant";

interface Apply {
  id: number;
  userId: number;
  managerId: number;
  name: string;
  userInfo: {
    id: number;
    name: string;
    phone: number;
  };
  managerUserInfo: {
    id: number;
    name: string;
    phone: number;
  };
  servicePhone: string;
  service: number;
}

@Component({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List
  }
})
export default class BusinessApplyList extends Vue {
  list = [];
  refreshing = false;

  created() {
    this.loadData();
  }
  onRefresh() {
    this.refreshing = true;
    this.loadData().finally(() => {
      this.refreshing = false;
    });
  }
  get canRecover() {
    return Boolean(localStorage.myCacheBusinessAddFlow);
  }

  loadData() {
    return this.$axios.post("/api/manage/business/applyList").then(e => {
      this.list = e.data.data;
    });
  }

  ignoreApply(id: number, index: number) {
    Dialog.confirm({
      message: "您确定要忽略此条申请吗?"
    }).then(() => {
      this.$axios.post("/api/manage/business/ignoreApply", { businessApplyId: id }).then(() => {
        this.list.splice(index, 1);
      });
    });
  }

  add(apply: Apply) {
    let p = Promise.resolve({});
    if (localStorage.myCacheBusinessAddFlow) {
      p = Dialog.confirm({
        message: "将会覆盖之前保存的添加运营商编辑内容"
      });
    }
    p.then(() => {
      if (apply) {
        localStorage.myCacheBusinessAddFlow = JSON.stringify({
          id: apply.id,
          name: apply.name,
          userId: apply.userId,
          userName: apply.userInfo.name,
          phone: apply.userInfo.phone,
          managerId: apply.managerId,
          managerName: apply.managerUserInfo.name,
          managerPhone: apply.managerUserInfo.phone,
          servicePhone: apply.servicePhone,
          service: apply.service,
          chargeProfitConfig: "",
          chargeProfitConfigRaw: [],
          replaceProfitConfig: "",
          replaceProfitConfigRaw: [],
          goodsProfitBaseConfig: "",
          goodsProfitBaseConfigRaw: [],
          permissions: "",
          permissionStr: ""
        });
      } else {
        localStorage.removeItem("myCacheBusinessAddFlow");
      }
      this.$router.push("/business/add");
    });
  }

  recover() {
    this.$router.push("/business/add");
  }
}
